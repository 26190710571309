<template>
  <div id="login-div">
    <div class="title-holder">
      <span> Fluke </span>
      {{ $t("pages.login.title") }} <br />
    </div>
    <div class="login-card">
      {{ $t("pages.reset.title") }}
      <br />
      <br />
      <v-form v-model="isValid" ref="form">
        <v-text-field
          outlined
          :label="$t('labels.formNewPassword')"
          type="password"
          :rules="[requiredRule]"
          v-model="auth.password"
        />
        <v-text-field
          type="password"
          outlined
          :rules="[requiredRule, mustMatch]"
          :label="$t('labels.formConfirmPassword')"
          v-model="auth.rePass"
        />
      </v-form>
    </div>
    <div class="btn-aligner">
      <div class="btns-aux"></div>
      <div class="btns-main">
        <v-btn large depressed block color="primary" @click="reset">
          {{ $t("labels.btnReset") }}
        </v-btn>
      </div>
    </div>

    <f-language-selector />
  </div>
</template>

<script>
import validatorRules from "../../helpers/validator_rules";
import FLanguageSelector from "./LanguageSelector.vue";

export default {
  components: {
    FLanguageSelector,
  },
  data: () => ({
    auth: {
      password: "",
      rePass: "",
      token: "",
    },
    isValid: false,
  }),
  computed: {
    requiredRule() {
      return validatorRules.required(this.$t("validation.required"));
    },
    mustMatch() {
      return validatorRules.confirmPassword(
        this.auth.password,
        this.$t("validation.confirmPassword")
      );
    },
  },
  methods: {
    async reset() {
      this.$refs.form.validate();
      if (!this.isValid) return false;

      this.auth.token = this.$route.params.token;
      const response = await this.$store.dispatch(
        "auth/setPasswordToken",
        this.auth
      );
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss">
#login-div {
  width: 480px;
  margin-bottom: 100px;

  .login-card {
    background-color: #fff;
    padding: 40px 40px 10px 40px;
    border-radius: 3px;
    margin-bottom: 30px;
  }

  .title-holder {
    padding: 0 40px;
    margin-bottom: 30px;

    span {
      display: block;
      font-weight: 700;
      font-size: 32px;
      margin-bottom: -10px;
    }
  }

  .btn-aligner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;

    .btns-main {
      button {
        width: 150px;
      }
    }
    .btns-aux {
      a {
        text-decoration: none;
        color: #a9a9a9;
      }
    }
  }

  .err {
    background-color: #ff5b5b;
    color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    margin-bottom: 30px;
    max-height: 0;
    transition: all 0.5s;
    opacity: 0;

    &.active {
      max-height: 100px;
      padding: 10px 15px;
      opacity: 1;
    }
  }

  .forgot {
    margin-top: 35px;
    font-size: 13px;
    cursor: pointer;
  }
}
</style>
